<template>
  <v-menu
      color="primary"
      v-model="menu"
      :close-on-content-click="true"
  >
    <template #activator="{ on, attrs }">

      <v-btn
          v-bind="attrs"
          color="primary"
          v-on="on"
          @click="enrichApolloById(apolloLead.id)"
      >
        {{email}}
      </v-btn>
    </template>

  </v-menu>

</template>

<script>
import api from "@/api/backend-api";
import {mapActions} from "vuex";

export default {
  name: "ApolloEmailMenu",
  props: ["apolloLead"],
  data: () => ({
    loading: false,
    menu: false,
    loadedEmail: ''
  }),
  computed: {
    email() {
      return this.apolloLead && this.apolloLead.email && this.apolloLead.email === 'email_not_unlocked@domain.com'
          ? 'reveal' : this.loadedEmail || this.apolloLead.email
    }
  },

  methods: {
    ...mapActions("apollo", ["enrichApolloById"]),

  },

}
</script>

<style scoped>

</style>
