<template>
  <v-menu
      v-if="domainName"
      v-model="menu"
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          x-small
          class="mb-1"
      >
        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ domainName }}</v-card-title>
      <v-card-text>
        <v-text-field
            v-model="price"
            :rules="priceRules"
            label="Price*"
            required
        />
        <v-text-field
            v-model="purchasePrice"
            :rules="priceRules"
            label="Purchase*"
            required
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue darken-1"
            text
            @click="list"
        >
          Send
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="menu = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["domainName"],
  data: () => ({
    menu: false,
    price: '',
    purchasePrice: '',
    priceRules: [
      (value) => !!value || "Required.",
      (value) => {
        const pattern = /^[0-9]+$/;
        return pattern.test(value) || "Invalid number.";
      },
    ],
  }),
  methods: {
    ...mapActions("inventory", [
      "importInventoryDomainAction",
    ]),
    list() {
      const payload = {
        name: this.domainName,
        price: this.price,
        purchasePrice: this.purchasePrice,
      };
      this.importInventoryDomainAction(payload);
      this.menu = false;
    },
  },
};
</script>

<style></style>
