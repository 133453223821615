<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          x-small
          v-on="on"
      >
        {{caption}}
      </v-btn>
    </template>
  <v-card>
    <div v-for="val in Object.keys(data)" :key="val + Math.random()">{{val}}: {{data[val]}}</div>
  </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["data", "caption"],
  data(){
    return{
      menu: false,
    }
  }
};
</script>

<style></style>
