<template>

  <v-data-table
      fixed-header
      :items="leads"
      :headers="headers"
      :expanded.sync="expanded"
      :loading="loading"
  >
    <template #[`item.email`]="{item}">
      <apollo-email-menu v-if="item.email" :apolloLead="item"/>
    </template>
    <template #[`item.sibling`]="{ item }">
      <a
          :href="`http://${item.organization.primary_domain}`"
          target="_blank"
      > {{ item.organization.primary_domain }}
        </a>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
          v-if="item.email && item.email!=='email_not_unlocked@domain.com'"
          fab
          x-small
          @click="startConversation(item)">
        <v-icon>
          mdi-mail
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <lead-card :lead="item.lead" class="pa-0 ma-0">
        </lead-card>
      </td>
    </template>
  </v-data-table>


</template>

<script>

import ApolloEmailMenu from "@/components/apollo/ApolloEmailMenu.vue";
import LeadCard from "@/components/leads/LeadCard.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ApolloLeadsTable",
  components: {ApolloEmailMenu, LeadCard},

  props: {
    domainName: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getLeads: "apollo/getLeadsByDomainName",
    }),
    ...mapGetters("conversations", ["getConversationByLeadId"]),

    leads() {
      return this.getLeads(this.domainName);
    }
  },

  async mounted() {
    this.loading = true;
    await this.loadLeadsByDomainName(this.domainName);
    this.loading = false;

  },
  methods: {
    ...mapActions("apollo", ["loadLeadsByDomainName"]),
    ...mapActions("conversations", ["loadConversationByApolloAction"]),


    async startConversation(item) {
      //Get the lead
      const apollo = {
        id: item.id,
        domain_name: this.domainName,
      }
      const conv = await this.loadConversationByApolloAction(apollo)
      console.log("loaded conversation", conv);
      //Expand Table
      item.lead = {id: conv.leadId};
      this.expanded.push(item);
      //Show conversation
    },
  },

  data() {
    return {
      expanded: [],
      lead: {},
      loading: true,
      headers: [
        {text: "Sibling", value: "sibling"},
        {text: "Phone", value: "phone"},
        {text: "Email", value: "email"},
        {text: "Actions", value: "actions", sortable: false},
        {text: "Name", value: "name"},
        {text: "Country", value: "country"},
        {text: "Title", value: "title"},
      ]
    }
  },

}
</script>

<style scoped>

</style>
