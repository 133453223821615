<template>
  <v-container fluid>
    <div class="text-center" v-if="loading">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <apollo-leads-table :domainName="domainName" >
    </apollo-leads-table>
    <lead-card v-for="lead in leads" :lead="lead" :key="lead.id" class="pa-0 ma-0">
    </lead-card>
<!--    <leads-table :leads="leads" >-->
<!--    </leads-table>-->
  </v-container>
</template>

<script>
import tools from "@/util/tools";
import api from "@/api/backend-api";
import {mapGetters, mapActions} from "vuex"
import ApolloLeadsTable from "@/components/apollo/ApolloLeadsTable.vue";
import LeadCard from "@/components/leads/LeadCard.vue";

export default {
  components: {
    LeadCard,
    ApolloLeadsTable,
  },
  props: ["domainName"],
  data: () => ({
    headers: [
      {text: "Name", value: "domainName"},
      {text: "Scan Time", value: "scanTime"},
      {text: "Created", value: "creation"},
      {text: "A", value: "A"},
      {text: "MX", value: "MX"},
      {text: "NS", value: "NS"},
      {text: "Country", value: "country"},
      {text: "Phones", value: "phones"},
      {text: "Emails", value: "emails"},
    ],
    error: null,
    leads: [],
    loading: false,
    expanded: [],


  }),
  computed: {

    ...mapGetters("domains", ["getDomainByName"]),
    ...mapGetters({
      getConversations: "conversations/getConversationsBySiblingName"
    }),

    scans() {


      let scanResults = this.domain
          ? [
            // ...this.domain.scanResults,
            ...this.domain.siblings.map((s) => s.scanResults).flat(1).sort((a, b) => a.domainName.length - b.domainName.length)

          ]
          : [];
      let unique = [...new Map(scanResults.map(item =>
          [item['domainName'].toLowerCase(), item])).values()];
      console.log("Unique scans: ", unique)
      let phones  = [ ...unique.map((s) => tools.fetchPhones(s)).flat(1)].filter(n => n.number);

      console.log("Got phones, ", phones);
      return unique;
    },
  },
  methods: {

  },
  async mounted() {
    if (!this.domain) {
      this.loading = true;
      let response = await api.loadLeadsByDomainName(this.domainName);
      this.leads = response.data;
      this.loading = false;

    }
  },
};
</script>

<style></style>
