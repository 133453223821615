<template>
  <v-menu
      color="primary"
      v-model="menu"
      :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">

      <v-btn
          v-bind="attrs"
          :color="valid?'primary':'error'"
          v-on="on"
      >
        {{getEmails}}
      </v-btn>
    </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in getEmails"
              :key="index"
              @click="validateEmail(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>


  </v-menu>

</template>

<script>


import {mapGetters} from 'vuex'
import tools from "@/util/tools";
import backendApi from "@/api/backend-api";

export default {

  props: ["lead"],
  data: () => ({
    menu: false,
    inputText: '',
    valid: false,
  }),
  computed: {
    ...mapGetters({
      getConversation: "conversations/getConversationByLeadId"
    }),
    conversation() {
      return this.getConversation(this.lead.id)
    },
    getEmails() {
      return tools.parseEmails(this.lead.email)
    }
  },

  methods: {
    async validateEmail(item) {

      let response = await backendApi.validateEmail(item);
      this.valid=response.data;
      const snack = {
        data: response.data,
        color: response.data?"green":"red",
        timeout: 1000,
        whois: false,
      };
      this.$store.dispatch("snackbar/setSnackbar", snack);

      if(!this.conversation){
        const payload = {
          id: this.lead.id,
          email: item

        }
        this.$store.dispatch(
            "conversations/loadConversationsByLeadAction", payload
        )
      }


    },

  }
}
</script>

<style></style>
