<template>
  <v-btn x-small :color="lead.textable?'green':'primary'"
         @click="retrieveConversation"> {{ lead.phone }}
  </v-btn>
</template>

<script>
export default {
  props: ['lead'],

  computed: {},
  methods: {
    async retrieveConversation() {
      const payload = {
        id: this.lead.id,
        phone: this.lead.phone

      }
      this.$store.dispatch(
          "conversations/loadConversationsByLeadAction", payload
      )


    },
  }

};
</script>

<style lang="scss" scoped></style>
